<template>
    <div class="step4">
        <h3 class="flex left"> <CheckCircleFilled class="icon"/>{{$t('updateOk')}}</h3>
        <div>{{$t('forget.step4Desc')}}</div>
        <a href="javascript:;" @click="$router.replace({name:'login'})">{{$t('forget.relogin')}}</a>
    </div>
</template>

<script>
    import {CheckCircleFilled} from '@ant-design/icons-vue'
    export default {
        components:{
            CheckCircleFilled,
        },

    }
</script>

<style lang="scss" scoped>
    .icon{
        color:#52C41A;
        font-size: 36px;
        margin-right: 18px;
    }
    h3{
        font-weight: normal;
        margin-bottom: 18px;
    }
    .step4{
        >*{
            margin-bottom: 6px;
            &:not(h3){
                margin-left: 56px;
            }
        }
    }
</style>