<template>
    <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:4}">
        <a-form-item :label="$t('forget.loginEmail')" name="acct">
            <a-input  v-model:value="formState.acct" />
        </a-form-item>
        <a-form-item label="" :wrapper-col="{offset:4}" >
            <a-button type="primary" @click="onSubmit">{{$t('forget.next')}}</a-button>
        </a-form-item>
    </a-form>
</template>

<script>
    import {reactive,toRaw,ref} from 'vue'
    import i18n from '../../locale/main'
    //import {postEmailCode} from "../../service/user";
    export default {
        emits:['next','changeInfo'],
        props:{
            info:{
                type:Object,
                default:null,
            }
        },
        setup(_,ctx) {
            let t=i18n.global.t
            const formRef = ref();
            const formState = reactive({
                acct: '',
            });

            const onSubmit = async () => {
                try {
                    await formRef.value.validate()
                    let data=toRaw(formState)
                    //await postEmailCode(data)
                    ctx.emit('next',1)
                    ctx.emit('changeInfo',data.acct)
                }catch (e) {
                    console.log(e)
                }
            };

            let validateAcct = async (rule, value) => {
                if(!value){
                    return Promise.reject(t('forget.loginEmailPlz'))
                }
                let emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
                if (!emailReg.test(value)) {
                    return Promise.reject(t('forget.loginEmailPlz2'));
                }
                return Promise.resolve();
            };

            const rules = {
                acct: [{validator: validateAcct, trigger: 'blur',},],
            }
            return {
                formState,
                onSubmit,
                rules,
                formRef,
            };
        },
    }
</script>

<style scoped>

</style>