<template>
    <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:4}">
        <a-form-item :label="$t('newPwd')" name="newPwd">
            <a-input-password v-model:value="formState.newPwd" :placeholder="$t('pwdPlz')"/>
        </a-form-item>
        <a-form-item :label="$t('rePwd')" name="rePwd">
            <a-input-password v-model:value="formState.rePwd" :placeholder="$t('pwdPlz')"/>
        </a-form-item>
        <a-form-item label="" :wrapper-col="{offset:4}">
            <a-button :disabled="formState.rePwd===''||formState.newPwd===''" type="primary" @click="onSubmit">
                {{$t('forget.next')}}
            </a-button>
        </a-form-item>
    </a-form>
</template>

<script>
    import {reactive, toRaw, ref, } from 'vue'
    import i18n from '../../locale/main'
    import {findPwd} from '../../service/user'

    export default {
        emits: ['next'],
        props: {
            info: {
                type: Object,
                default: null,
            }
        },
        setup(props, ctx) {
            if (!props.info || !props.info.updateData || !props.info.acct) {
                ctx.emit('next', 0)
            }

            let t = i18n.global.t
            const formRef = ref();
            const formState = reactive({
                newPwd: '',
                rePwd: '',
            });
            let pwdReg = new RegExp('^[0-9A-Za-z_!@#$%^&*]{6,32}$');
            const onSubmit = async () => {
                try {

                    await formRef.value.validate()
                    let data = toRaw(formState)
                    let infoData = props.info.updateData
                    await findPwd({pwd: data.newPwd, uid: infoData.uid, uuid: infoData.uuid, sign: infoData.sign})
                    ctx.emit('next', 3)
                } catch (e) {
                    console.log(e)

                }
            };

            let validatePwd1 = async (rule, value) => {
                if (!value) {
                    return Promise.reject(t('pwdPlz1'))
                }
                if (!pwdReg.test(value)) {
                    return Promise.reject(t('pwdPlz'))
                }

                return Promise.resolve();
            };
            let validatePwd2 = async (rule, value) => {
                if (!value) {
                    return Promise.reject(t('pwdPlz2'))
                }
                if (!pwdReg.test(value)) {
                    return Promise.reject(t('pwdPlz'))
                }
                if (value !== formState.newPwd) {
                    return Promise.reject(t('pwdPlz3'))
                }

                return Promise.resolve();
            };

            const rules = {
                newPwd: [{validator: validatePwd1, trigger: 'blur',},],
                rePwd: [{validator: validatePwd2, trigger: 'blur',},],
            }
            return {
                formState,
                onSubmit,
                rules,
                formRef,
            };
        },
    }
</script>

<style scoped>

</style>