<template>
    <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:4}">
        <a-form-item label="" >
            <a-alert :message="time<60?$t('forget.codeInfo',{time:time}):$t('forget.codeInfo2')" type="info" show-icon style="width: 100%;" >
                <template #closeText>
                    <a v-if="time<60" href="javascript:;" :disabled="true" @click.stop="stop">{{$t('forget.resend')}}</a>
                    <a v-else href="javascript:;"  @click.stop="test2">{{$t('forget.resend')}}</a>
                </template>
            </a-alert>
        </a-form-item>
        <a-form-item :label="$t('forget.loginEmail')">
            {{info.acct}}
        </a-form-item>
        <a-form-item :label="$t('forget.code')" name="code">
            <a-input  v-model:value="formState.code" />
        </a-form-item>
        <a-form-item label="" :wrapper-col="{offset:4}" >
            <a-button :disabled="formState.code===''" type="primary" @click="onSubmit">{{$t('forget.next')}}</a-button>
        </a-form-item>
    </a-form>
</template>

<script>
    import {reactive, toRaw, ref, onMounted,} from 'vue'
    import i18n from '../../locale/main'
    import {postEmailCode,verifyEmailCode} from '../../service/user'
    let st
    export default {
        emits:['next','changeInfo'],
        props:{
          info:{
              type:Object,
              default:null,
          }
        },
        setup(props,ctx) {
            if(!props.info||!props.info.acct){
                ctx.emit('next',0)
            }

            let t=i18n.global.t
            const formRef = ref();
            let time = ref(59);
            const formState = reactive({
                code: '',
            });


            let changeTime=async ()=>{
                if(st)return;
                await postEmailCode(props.info)
                st=setInterval(()=>{
                    time.value=time.value-1
                    if(time.value===0){
                        time.value=60
                        clearInterval(st)
                        st=undefined
                    }
                },1000)
            }

            const onSubmit = async () => {
                try {
                    await formRef.value.validate()
                    let data=toRaw(formState)
                    let rs=await verifyEmailCode({...data,...props.info})
                    ctx.emit('next',2)
                    ctx.emit('changeInfo',rs.data.result)
                }catch (e) {
                    console.log(e)
                }
            };


            const rules = {
                code: [
                    {required: true, message: t('forget.codePlz'), trigger: 'blur',},
                ],
            }

            onMounted(()=>{
                changeTime()
            })

            return {
                formState,
                onSubmit,
                rules,
                formRef,
                time,
                changeTime,
                stop(){},
                test2(){
                    changeTime()
                },
            };
        },
    }
</script>

<style scoped>

</style>