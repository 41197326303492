<template>
    <div class="flex">
        <div class="forget">
            <h4>{{$t('forget.title')}} <a style="font-size: 14px;font-weight: normal;" @click="$router.go(-1)">{{$t('back')}}</a></h4>
            <a-steps :current="status">
                <a-step :title="$t('forget.step1')"/>
                <a-step :title="$t('forget.step2')"/>
                <a-step :title="$t('forget.step3')"/>
                <a-step :title="$t('forget.step4')"/>
            </a-steps>
            <a-row type="flex" justify="center">
                <a-col :span="16">
                    <step1 v-if="status===0" :info="info" @changeInfo="getAcct" @next="changeStatus"/>
                    <step2 v-if="status===1" :info="info" @changeInfo="getUpdateData" @next="changeStatus"/>
                    <step3 v-if="status===2" :info="info" @next="changeStatus"/>
                    <step4 v-if="status===3" />
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import step1 from "./step1";
    import step2 from "./step2";
    import step3 from "./step3";
    import step4 from "./step4";
    import {ref,reactive,watch} from 'vue'
    import {useRouter} from 'vue-router'
    import constName from '../../utils/constName'
    export default {
        components:{
            step1,
            step2,
            step3,
            step4,
        },
        setup(){
            let router=useRouter()
            let status=ref(0)
            let info=reactive({
                acct:'',
                updateData:null
            })
            watch(()=>status.value,()=>{
                if(status.value===3){
                    setTimeout(()=>{
                        router.push({name:constName.route.login})
                    },3000)
                }
            })
            return{
                status,
                info,
                changeStatus(val){
                    status.value=val

                },
                getAcct(e){
                    info.acct=e

                },
                getUpdateData(e){
                    info.updateData=e

                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.forget{
    margin-top: 64px;
    width: 800px;
    >*{
        margin-bottom:50px ;
    }
}
</style>